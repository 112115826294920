<template>
    <div>
        <!-- NUEVO PROVEEDOR -->
        <Dialog :header="tituloDetalles" :visible.sync="dialogDetalles" :style="{ width: '90vw' }" :maximizable="false"
            :contentStyle="{ height: '700px' }" position="top" :modal="true">
            <DetallesIngresos></DetallesIngresos>
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <H2>Ingresos por RFC</H2>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-4 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaIncial" dateFormat="dd/MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaFinal" dateFormat="dd/MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getIngresos()" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field col-12 mt-2 mb-0">
                        <hr size=7 color="#E64747">
                    </div>
                </div>
                <DataTable removableSort :exportFilename="nombreExcel" ref="IngresosMes" showGridlines
                    :filters.sync="filtros" class="mt-1 p-datatable-sm" :value="itemsIngresos" :scrollable="true"
                    scrollHeight="550px" :loading="loadingIngresos" filterDisplay="menu"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="15">
                    <template #header>
                        <div class="p-fluid grid">
                            <div class="col-4 text-left ">
                                <span class="p-input-icon-left" v-if="itemsIngresos.length != 0">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtros['global'].value" placeholder="Filtrar por..." />
                                </span>
                            </div>
                            <div class="col-8 text-right ">
                                <Button type="button" icon="pi pi-filter-slash" style="width: 100px;" label="Limpiar"
                                    class="p-button-outlined mr-2" @click="limpiarFiltros()" />
                                <Button icon="pi pi-external-link" style="width: 200px;" label="Exportar Excel"
                                    @click="exportarIngresos($event)" />
                            </div>
                        </div>
                    </template>
                    <Column sortable field="rfc" header="RFC"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="subTotal" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotal) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="descuento" header="Descuento"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.descuento) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="total" header="Total"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.total) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button @click="verDetalles(slotProps.data)" icon="pi pi-bars" class="p-button-rounded"
                                v-tooltip.bottom="'Total Formas de Pago'" />
                        </template>
                    </Column>
                    <ColumnGroup type="footer">
                        <Row>
                            <Column footer="Total:" :colspan="1" :footerStyle="{ 'text-align': 'right' }" />
                            <Column />
                            <Column :footer="sumaSubtotal" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaDescuento" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaTotal" :footerStyle="{ 'text-align': 'right' }" />
                            <Column />
                        </Row>
                    </ColumnGroup>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import DetallesIngresos from './DetalleIngresos.vue'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import * as XLSX from 'xlsx/xlsx.js'

export default {
    components: {
        DetallesIngresos
    },
    data() {
        return {
            fechaIncial: new Date(),
            fechaFinal: new Date(),
            loadingIngresos: false,
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'descuento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            },
            dialogDetalles: false,
            nombreExcel: '',
            tituloDetalles: ''
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        itemsIngresos() {
            return this.$store.state.listaIngresosRFCStore
        },
        sumaSubtotal() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.subTotal;
            }

            return this.formatCurrency(total);
        },
        sumaDescuento() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.descuento;
            }

            return this.formatCurrency(total);
        },
        sumaTotal() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.total;
            }

            return this.formatCurrency(total);
        },

    },
    created() {
    },
    methods: {
        async getIngresos() {
            this.loadingIngresos = true;
            this.$store.state.listaIngresosRFCStore = []
            try {
                let fI = moment(this.fechaIncial).format('YYYY-MM-DD')
                let fF = moment(this.fechaFinal).format('YYYY-MM-DD')
                let response = await axios.get('ReporteComprobantesIngresos/GetIngresosPorRfc/' + this.token.empresaBase + '/' + fI + '/' + fF);
                console.log(response.data)
                this.$store.state.listaIngresosRFCStore = response.data
                this.loadingIngresos = false;
            } catch (error) {
                console.log(error);
                this.loadingIngresos = false;
            }
        },
        verDetalles(item) {
            this.tituloDetalles = 'Comprobantes - ' + item.rfc

            console.log(item)
            this.dialogDetalles = true
            this.$store.state.listaDetallesIngresosStore = []
            this.$store.state.listaDetallesIngresosStore = item.detalle
        },
        exportarIngresos() {
            let fI = moment(this.fechaIncial).format('DD-MM-YYYY')
            let fF = moment(this.fechaFinal).format('DD-MM-YYYY')

            this.nombreExcel = 'IngresosRFC_' + fI + '_' + fF + '.xlsx'
            // this.$refs.IngresosMes.exportCSV();
            const rows = this.$refs.IngresosMes.processedData; // Obtén los datos de la DataTable
            console.log(rows)
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            XLSX.writeFile(workbook, this.nombreExcel);
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        limpiarFiltros() {
            this.filtros = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'descuento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        }
    },
}
</script>