<template>
    <div>
        <div class="p-d-flex">
            <Toast />
        </div>
        <Card>
            <!-- TITULO CARD -->
            <template #title>
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0  mb-0  pl-2 mr-0 pb-2 pt-2 pl-0 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        Configuración
                    </div>
                    <div class="mt-0 pl-2 mr-0 pb-1 mb-0 pt-1 pl-0 pr-3 pb-0 pa-0 text-right field col-6 md:col-6">
                        <Button icon="pi pi-save" style="height:50px; width:50px; background:#E64747"
                            class="p-button-rounded" @click="guardarOactualizar()" />
                    </div>
                </div>
                <ProgressBar :value="100" style="height: .4em" />
            </template>
            <template #content>
                <div class="p-d-flex justify-center p-fluid grid formgrid">
                    <div class="p-fluid grid field col-6">
                        <!-- RFC -->
                        <div class="field col-6">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText maxlength="13" v-model="empresa.rfc" @change="validaRegimen" id="rfc"
                                        type="text" />
                                    <label for="rfc">RFC</label>
                                </span>
                            </span>
                        </div>
                        <!-- NOMBRE -->
                        <div class="field col-6">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText id="cp" v-model="empresa.domicilioFiscal" maxlength="5" type="number" />
                                    <label for="cp">Código Postal</label>
                                </span>
                            </span>
                        </div>
                        <!-- CODIGO POSTAL -->
                        <div class="field col-12">
                            <span class="p-float-label">
                                <span class="p-float-label">
                                    <InputText v-model="empresa.nombre" id="nombreE" type="text" />
                                    <label for="nombreE">Nombre</label>
                                </span>
                            </span>
                        </div>
                        <!-- REGIMEN FISCAL -->
                        <div class="justify-left field col-12">
                            <span class="p-float-label">
                                <Dropdown v-model="empresa.regimenFiscal" style="text-align: left"
                                    :options="itemsRegimenFiscal" optionLabel="regimenFiscal" />
                                <label>Regimen Fiscal</label>
                            </span>
                        </div>
                        <!-- LOGO DE LA EMPRESA -->
                        <div class="field col-12">
                            <h3>Seleccione la imagen</h3>
                            <FileUpload name="demo[]" :customUpload="true" @uploader="uploadImage" accept="image/*"
                                :maxFileSize="5242880">
                                <template #empty>
                                    <p>Arrastre y suelte para cargar</p>
                                </template>
                            </FileUpload>
                        </div>
                    </div>
                    <!-- VISUALIZADOR DEL LOGO -->
                    <div class="p-fluid grid field col-6">
                        <div class="field col-12">
                            <h2>Logo de la empresa</h2>
                            <ImagePreview :src="empresa.logo.base64" alt="Image" width="500" preview />
                        </div>
                    </div>
                </div>
                <!-- CONFIGURACION DE CORREO -->
                <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0  mb-0  pl-2 mr-0 pb-2 pt-2 pl-0 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        <h2>Correo</h2>
                    </div>
                    <div class="mt-0 pl-2 mr-0 pb-1 mb-0 pt-1 pl-0 pr-3 pb-0 pa-0 text-right field col-6 md:col-6">
                        <Button @click="guardarConfigCorreo()" icon="pi pi-save"
                            style="height:50px; width:50px; background:#E64747" class="p-button-rounded" />
                    </div>
                </div>
                <ProgressBar :value="100" style="height: .4em" />
                <div class="p-fluid mt-3 grid field col-12">
                    <!-- NOMBRE CORREO-->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="nombre" v-model="correo.nombreCorreo" type="text" />
                                <label for="nombre">Nombre</label>
                            </span>
                        </span>
                    </div>
                    <!-- CORREO -->
                    <div class="field col-6">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="correo" v-model="correo.correo" type="text" />
                                <label for="correo">Correo</label>
                            </span>
                        </span>
                    </div>
                    <!-- CONTRASEÑA CORREO -->
                    <div class="field col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <Password id="contra" v-model="correo.password" toggleMask></Password>
                                <label for="contra">Contraseña</label>
                            </span>
                        </span>
                    </div>
                    <!-- PUERTO -->
                    <div class="field col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="puerto" v-model="correo.puerto" type="text" />
                                <label for="puerto">Puerto</label>
                            </span>
                        </span>
                    </div>
                    <!-- HOST -->
                    <div class="field col-4">
                        <span class="p-float-label">
                            <span class="p-float-label">
                                <InputText id="host" v-model="correo.host" type="text" />
                                <label for="host">Host</label>
                            </span>
                        </span>
                    </div>
                </div>
                <!-- SELLOS -->
                <!-- <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0  mb-0  pl-2 mr-0 pb-2 pt-2 pl-0 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        <h2>Sellos</h2>
                    </div>
                    <div class="mt-0 pl-2 mr-0 pb-1 mb-0 pt-1 pl-0 pr-3 pb-0 pa-0 text-right field col-6 md:col-6">
                        <Button @click="guardarSellos()" icon="pi pi-save"
                            style="height:50px; width:50px; background:#E64747" class="p-button-rounded" />
                    </div>
                </div> -->
                <!-- <ProgressBar :value="100" style="height: .4em" />
                <div class="p-fluid mt-3 grid field col-12">
                    CONTRASEÑA SELLOS
                    <div class="field col-12">
                        <span class="p-float-label">
                            <span class="p-float-label" @change="carga">
                                <Password id="contraSellos" v-model="sellos.csdContra" toggleMask>
                                </Password>
                                <label for="contraSellos">Contraseña</label>
                            </span>
                        </span>
                    </div>
                    <div class="field col-6">
                        <FileUpload name="demo[]" @uploader="uploadSelloKey" accept=".key" :customUpload="true"
                            :maxFileSize="5242880">
                            <template #empty>
                                <p>Arrastre y suelte el archivo Key.</p>
                            </template>
                        </FileUpload> 
                    </div>
                    <div class="field col-6">
                        <FileUpload name="demo[]" @uploader="uploadSelloCer" accept=".cer" :customUpload="true"
                            :maxFileSize="5242880">
                            <template #empty>
                                <p>Arrastre y suelte el archivo Cer.</p>
                            </template>
                        </FileUpload>
                    </div>

                </div> -->
                <!-- EXTRAS PARA VENTAS -->
                <!-- <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                    <div class="mt-0  mb-0  pl-2 mr-0 pb-2 pt-2 pl-0 pr-0 pb-0 pa-0 justify-right field col-6 md:col-6">
                        <h2>Extras Ventas</h2>
                    </div>
                    <div class="mt-0 pl-2 mr-0 pb-1 mb-0 pt-1 pl-0 pr-3 pb-0 pa-0 text-right field col-6 md:col-6">
                        <Button @click="guardarOactualizar()" icon="pi pi-save"
                            style="height:50px; width:50px; background:#E64747" class="p-button-rounded" />
                    </div>
                </div>
                <ProgressBar :value="100" style="height: .4em" />
                <div class="p-fluid mt-3 grid field col-12">
                    <Editor v-model="empresa.notasVentasA" editorStyle="height: 250px" />
                </div>
                <div class="p-fluid mt-3 grid field col-12">
                    <Editor v-model="empresa.notasVentasB" editorStyle="height: 250px" />
                </div> -->
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            itemsRegimenFiscal: [],
            value: 0,

        }
    },
    computed: {
        correo() {
            return this.$store.state.correoStore;
        },
        empresa() {
            return this.$store.state.empresaStore;
        },
        sellos() {
            return this.$store.state.sellosStore;
        },
        usuario() {
            return this.$store.state.usuario;
        },

    },

    created() {
        this.GetRegimenFiscal();
        this.GetEmpresa();
        this.GetCorreo();
    },
    methods: {
        async GetRegimenFiscal() {
            try {
                let response = await axios.get("CatalogosSat/GetRegimenFiscal/");
                console.log(response.data)
                this.$store.state.listRegimenFiscalStore = response.data;

            } catch (error) {
                console.log(error);
            }
        },
        validaRegimen() {
            console.log(this.empresa.rfc)
            if (this.empresa.rfc.length === 13) {
                console.log('fisica')
                return this.itemsRegimenFiscal = this.$store.state.listRegimenFiscalStore.filter(regimen => regimen.fisica === 'Sí');
            }
            if (this.empresa.rfc.length === 12) {
                console.log('moral')
                return this.itemsRegimenFiscal = this.$store.state.listRegimenFiscalStore.filter(regimen => regimen.moral === 'Sí');
            }
        },
        async uploadImage(event) {
            console.log(event)

            const file = event.files[0];

            try {
                const result = await this.convertBase64(file);
                this.$store.state.empresaStore.logo.base64 = result;
                this.$store.state.empresaStore.logo.extension = file.type;
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async uploadSelloKey(event) {
            this.value = this.value + 35
            console.log(this.value)
            console.log(event)
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.sellos.csdKey.base64 = result
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        async uploadSelloCer(event) {
            this.value = this.value + 35
            console.log(this.value)
            console.log(event)
            const file = event.files[0];
            try {
                const result = await this.convertBase64(file);
                this.sellos.csdCer.base64 = result
            } catch (error) {
                console.log('error')
                console.error(error);
                return;
            }
        },
        convertBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = () => reject(reader.error);
            });
        },
        guardarOactualizar() {
            if (this.empresa.idEmpresa == 0) {
                this.guardarConfig();
            } else {
                this.actualizarConfig();
            }
        },
        async guardarConfig() {
            this.empresa.usuarioCreador = this.usuario.nombre
            console.log(this.usuario.empresaBase)
            try {
                let response = await axios.post('Empresas/GuardarConfiguracion/' + this.usuario.empresaBase, this.empresa)
                console.log(response.data)
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Configuración guardada.', life: 3000 });
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al guardar.', life: 3000 });
            }
        },

        async actualizarConfig() {
            this.empresa.usuarioCreador = this.usuario.nombre
            try {
                let response = await axios.post('Empresas/ActualizarConfiguracion/' + this.usuario.empresaBase, this.empresa)
                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Configuración actualiazada.', life: 3000 });
                console.log(response.data)
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        async GetEmpresa() {
            try {
                let response = await axios.get('Empresas/GetEmpresa/' + this.usuario.empresaBase);
                console.log(response.data)
                if (response.data.idEmpresa != 0) {
                    this.$store.state.empresaStore = response.data
                }
                this.validaRegimen();
            } catch (error) {
                console.log(error);
            }
        },
        async guardarConfigCorreo() {
            this.correo.idEmpresa = this.$store.state.empresaStore.idEmpresa
            try {
                let response = await axios.post('Empresas/ActualizaCorreo/' + this.usuario.empresaBase, this.correo)
                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Configuración actualiazada.', life: 3000 });
                console.log(response.data)
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Error al actualizar.', life: 3000 });
            }
        },

        async GetCorreo() {
            try {
                let response = await axios.get('Empresas/GetCorreo/' + this.usuario.empresaBase);
                console.log(response.data)
                this.$store.state.correoStore = response.data
            } catch (error) {
                console.log(error);
            }
        },

        async guardarSellos() {
            if (this.value < 100) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Cargue la informacion restante.', life: 3000 });
                return
            }

            this.sellos.idEmpresa = this.empresa.idEmpresa
            this.sellos.rfc = this.empresa.rfc
            this.sellos.usuarioModificador = this.usuario.nombre
            console.log(this.sellos)
            try {
                let response = await axios.post('Empresas/ActualizaArchivos/' + this.usuario.empresaBase, this.sellos)
                this.$toast.add({ severity: 'success', summary: 'Actualizado', detail: 'Sellos Cargados.', life: 3000 });
                this.value = 0
                console.log(response.data)
            } catch (error) {
                console.log(error)
                this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Archivos no validos, verifique su información.', life: 3000 });
            }
        },

        carga() {
            if (this.sellos.csdContra == '') {
                this.value = this.value - 30
            }
            if (this.sellos.csdContra != '') {
                return this.value = 30
            }

        }
    },
}
</script>

<style>
.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #E64747;
}
</style>