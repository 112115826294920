<template>
  <div class="mt-8 text-center">
    <img src="../../src/assets/logo_contago.jpg" @click="funcion()" height="200">
    <!-- <span> {{ "La resolución de tu pantalla es: " + window.screen.width + " x " + window.screen.height }}</span> -->

  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  created() {
    this.getEmpresa();

  },
  computed: {
    empresa() {
      return this.$store.state.empresaStore;
    },
    token() {
      return this.$store.state.usuario;
    },
  },
  methods: {
    async getEmpresa() {
      try {
        this.loadingMensaje = 'Consultando datos...'
        let response = await axios.get('Empresas/GetEmpresa/' + this.token.empresaBase);
        this.$store.state.empresaStore = response.data
      } catch (error) {
        console.log(error)
        this.dialogLoading = false;
      }
    },
  }
}
</script>
<style type='text/css'>
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}
</style>
