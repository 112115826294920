<template>
    <div>
        <!-- DETALLES Ingresos -->
        <Dialog :header="tituloDetalles" :visible.sync="dialogDetalles" :style="{ width: '90vw' }" :maximizable="false"
            :contentStyle="{ height: '700px' }" position="top" :modal="true">
            <DetallesIngresos></DetallesIngresos>
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <h2>Ingresos por Comparativa</h2>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-4 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaI" view="year" dateFormat="yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaF" view="year" dateFormat="yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getIngresos()" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="field col-12 mt-2 mb-0">
                        <hr size=7 color="#E64747">
                    </div>
                </div>
                <DataTable removableSort :exportFilename="nombreExcel" ref="IngresosMes" showGridlines
                    :filters.sync="filtros" class="mt-1 p-datatable-sm" :value="itemsIngresos" :scrollable="true"
                    scrollHeight="600px" :loading="loadingIngresos" :rows="12">

                    <template #header>
                        <div class="flex justify-content-between">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-if="itemsIngresos.length != 0" v-model="filtros['global'].value"
                                    placeholder="Filtrar por..." />
                            </span>
                            <Button icon="pi pi-external-link" label="Exportar Excel" @click="exportarIngresos($event)" />
                        </div>
                        <div class="p-fluid grid">
                            <div class="col-6 text-center">
                                <h3>{{ fechaAHeader }}</h3>
                            </div>
                            <div class="col-6 text-center">
                                <h3>{{ fechaBHeader }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column sortable field="mes" header="Mes"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                    </Column>

                    <Column sortable field="subTotalA" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotalA) }}</span>
                        </template>
                    </Column>
                    <Column sortable field="descuentoA" header="Descuento"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.descuentoA) }}</span>
                        </template>
                    </Column>
                    <Column sortable field="totalA" header="Total"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.totalA) }}</span>
                        </template>
                    </Column>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button @click="verDetallesA(slotProps.data)" icon="pi pi-bars" class="p-button-rounded"
                                v-tooltip.bottom="'Total Formas de Pago'" />
                        </template>
                    </Column>
                    <Column sortable field="subTotalB" header="SubTotal"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.subTotalB) }}</span>
                        </template>
                    </Column>
                    <Column sortable field="descuentoB" header="Descuento"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.descuentoB) }}</span>
                        </template>
                    </Column>
                    <Column sortable field="totalB" header="Total"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.totalB) }}</span>
                        </template>
                    </Column>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }">
                        <template #body="slotProps">
                            <Button @click="verDetallesB(slotProps.data)" icon="pi pi-bars" class="p-button-rounded"
                                v-tooltip.bottom="'Total Formas de Pago'" />
                        </template>
                    </Column>
                    <ColumnGroup type="footer">
                        <Row>
                            <Column footer="Total:" :colspan="1" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaSubtotalA" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaDescuentoA" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaTotalA" :footerStyle="{ 'text-align': 'right' }" />
                            <Column />
                            <Column :footer="sumaSubtotalB" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaDescuentoB" :footerStyle="{ 'text-align': 'right' }" />
                            <Column :footer="sumaTotalB" :footerStyle="{ 'text-align': 'right' }" />
                            <Column />
                        </Row>
                    </ColumnGroup>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import DetallesIngresos from './DetalleIngresos.vue'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import * as XLSX from 'xlsx/xlsx.js'

export default {
    components: {
        DetallesIngresos
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            loadingIngresos: false,
            itemsIngresos: [],
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },

            dialogDetalles: false,
            nombreExcel: '',
            tituloDetalles: '',
            fechaAHeader: '',
            fechaBHeader: ''
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        sumaSubtotalA() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.subTotalA;
            }

            return this.formatCurrency(total);
        },
        sumaDescuentoA() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.descuentoA;
            }

            return this.formatCurrency(total);
        },
        sumaTotalA() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.totalA;
            }

            return this.formatCurrency(total);
        },
        sumaSubtotalB() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.subTotalB;
            }

            return this.formatCurrency(total);
        },
        sumaDescuentoB() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.descuentoB;
            }

            return this.formatCurrency(total);
        },
        sumaTotalB() {
            let total = 0;
            for (let item of this.itemsIngresos) {
                total += item.totalB;
            }

            return this.formatCurrency(total);
        },
    },
    created() {
    },
    methods: {
        async getIngresos() {
            this.itemsIngresos = []
            this.loadingIngresos = true;
            try {
                let fI = moment(this.fechaI).format('YYYY')
                let ff = moment(this.fechaF).format('YYYY')
                let response = await axios.get('ReporteComprobantesIngresos/GetIngresosComparativa/' + this.token.empresaBase + '/' + fI + '/' + ff);
                console.log(response.data)
                this.itemsIngresos = response.data
                this.fechaAHeader = moment(this.fechaI).format('YYYY')
                this.fechaBHeader = moment(this.fechaF).format('YYYY')
                this.loadingIngresos = false;
            } catch (error) {
                console.log(error);
                this.loadingIngresos = false;
            }
        },
        verDetallesA(item) {
            let año = moment(this.fechaI).format('YYYY')
            this.tituloDetalles = 'Comprobantes - ' + item.mes + '/' + año
            console.log(item)
            this.dialogDetalles = true
            this.$store.state.listaDetallesIngresosStore = []
            this.$store.state.listaDetallesIngresosStore = item.detalleA
        },
        verDetallesB(item) {
            let año = moment(this.fechaF).format('YYYY')
            this.tituloDetalles = 'Comprobantes - ' + item.mes + '/' + año
            console.log(item)
            this.dialogDetalles = true
            this.$store.state.listaDetallesIngresosStore = []
            this.$store.state.listaDetallesIngresosStore = item.detalleB
        },
        exportarIngresos() {
            let fI = moment(this.fechaI).format('YYYY')
            let fF = moment(this.fechaF).format('YYYY')
            this.nombreExcel = 'IngresosComparativa-' + fI + '_' + fF + '.xlsx'
            // this.$refs.IngresosMes.exportCSV();

            const rows = this.$refs.IngresosMes.processedData; // Obtén los datos de la DataTable
            console.log(rows)
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Hoja1');

            XLSX.writeFile(workbook, this.nombreExcel);
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
    },
}
</script>