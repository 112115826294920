<template>
    <div class="card">
        <Dialog :visible.sync="dialogFuente" :style="{ width: '5000px' }" :closable="false" :modal="true" position="top">
            <FuenteFinanciamiento @cierraDialogFuenta="dialogFuente = false"></FuenteFinanciamiento>
        </Dialog>

        <DataTable removableSort ref="detalles" :exportFilename="nombreExcel" showGridlines :filters.sync="filtros"
            scrollHeight="550px" class="mt-1 p-datatable-sm" :value="items" :scrollable="true" :rows="15"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 15, 25, 50]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true">
            <template #header>
                <div class="flex justify-content-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                    </span>
                    <Button icon="pi pi-external-link" label="Nuevo" @click="dialogFuente = true" />
                </div>
            </template>
            <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                <template #body="slotProps">
                    <!-- <Button @click="verPDF(slotProps.data)" icon="pi pi-file-pdf"
                            class="p-button-rounded p-button-danger" v-tooltip.bottom="'Ver PDF'" /> -->
                </template>
            </Column>
            <Column sortable field="rfc" header="RFC" :styles="{ 'min-width': '150px' }">
            </Column>
            <Column sortable field="nombre" header="Nombre" :styles="{ 'min-width': '400px' }">
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import FuenteFinanciamiento from './FuenteFinanciamiento.vue';

export default {
    components: {
        FuenteFinanciamiento
    },
    data() {
        return {
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            nombreExcel: '',
            // items: [],
            dialogFuente: false,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaFuentesFinanciamiento;
        },
    },
    created() {
        this.GetFuenteFinanciamiento();
    },
    methods: {
        async GetFuenteFinanciamiento() {
            try {
                let response = await axios.get('ReporteComprobantesIngresos/GetFuenteFinanciamiento/' + this.token.empresaBase)
                console.log(response.data)
                this.$store.state.listaFuentesFinanciamiento = response.data;
            } catch (error) {
                console.log(error)
            }
        },

    },
}
</script>