<template>
    <div class="card">
        <!-- DETALLE DE LOS INGRESOS -->
        <Dialog :header="tituloDetalles" :visible.sync="dialogDetalles" :style="{ width: '90vw' }" :maximizable="false"
            :contentStyle="{ height: '700px' }" position="top" :modal="true">
            <DetalleIngresos></DetalleIngresos>
        </Dialog>

        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <DataTable removableSort :exportFilename="nombreExcel" showGridlines scrollHeight="550px"
            class="mt-1 p-datatable-sm" :value="items" :scrollable="true" :rows="15"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 15, 25, 50]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true">
            <template #header>
                <div class="flex justify-content-between">
                    <div>

                    </div>
                    <!-- FECHAS -->
                    <div class="col-6 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaI" dateFormat="dd/MM/yy" :yearNavigator="true"
                                            yearRange="2020:2030" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar v-model="fechaF" dateFormat="dd/MM/yy" :yearNavigator="true"
                                            yearRange="2020:2030" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4 mr-1"
                                        @click="GetReporte()" />
                                    <Button icon="pi pi-file-excel" class="p-button-success pl-4 pr-4" @click="Export()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">

                <template #body="slotProps">
                    <Button @click="verDetalles(slotProps.data)" icon="pi pi-bars" class="p-button-rounded"
                        v-tooltip.bottom="'Total Formas de Pago'" />
                </template>
            </Column>
            <Column sortable field="clave" header="Fondo" :styles="{ 'min-width': '400px' }">
            </Column>
            <Column sortable field="importe" header="Importe" :styles="{ 'min-width': '150px' }"
                :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.importe) }}</span>
                </template>
            </Column>
            <ColumnGroup type="footer">
                <Row>
                    <Column footer="Total:" :colspan="2" :footerStyle="{ 'text-align': 'right' }" />
                    <Column :footer="sumaImporte" :footerStyle="{ 'text-align': 'right' }" />
                </Row>
            </ColumnGroup>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import DetalleIngresos from '../General/DetalleIngresos.vue';

export default {
    components: {
        DetalleIngresos,
    },
    data() {
        return {
            nombreExcel: '',
            items: [],
            fechaI: new Date(),
            fechaF: new Date(),
            dialogDetalles: false,
            tituloDetalles: '',
            loading: false,
            loadingMensaje: '',
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        sumaImporte() {
            let total = 0;
            for (let item of this.items) {
                total += item.importe;
            }

            return this.formatCurrency(total);
        },
    },
    created() {

    },
    methods: {
        async GetReporte() {
            this.loading = true;
            this.loadingMensaje = 'Consultando datos, espere...';
            try {
                let fI = moment(this.fechaI).format('YYYY-MM-DD')
                let fF = moment(this.fechaF).format('YYYY-MM-DD')
                let response = await axios.get('ReporteComprobantesIngresos/GetReporteFondo/' + this.token.empresaBase + '/' + fI + '/' + fF);
                // console.log(response.data)
                this.items = response.data;
                this.loading = false;
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        Export() {

        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },

        verDetalles(item) {
            this.dialogDetalles = true
            this.$store.state.listaDetallesIngresosStore = []
            this.$store.state.listaDetallesIngresosStore = item.comprobantes
            this.tituloDetalles = item.clave + ' | ' + this.formatCurrency(item.importe)
        },
    },
}
</script>