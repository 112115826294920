<template>
    <div>
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <vista-pdf @cierraDialogPdf="closeDialogPdf"></vista-pdf>
        </Dialog>

        <!-- DIALOGO DE SPINNER -->
        <Dialog class="dialogoTransparente shadow-none" :showHeader="false" style="background: transparent;"
            :visible.sync="loadingSpinner" :closable="false" position="center" :containerStyle="{ width: '300px' }">
            <ProgressSpinner />
        </Dialog>

        <Card class="shadow-none">
            <template #content>
                <div class="p-fluid grid">
                    <!-- TITULO -->
                    <div class="col-8 text-left pa-0 ma-0 pl-3">
                        <h2>Cuentas por Cobrar por Periodo</h2>
                    </div>
                    <!-- FECHAS -->
                    <div class="col-4 text-right">
                        <div class="text-left p-d-flex justify-center p-fluid grid formgrid">
                            <div class="col-12 md:col-12 text-left">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaI" view="month" dateFormat="MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Inicial</label>
                                    </span>
                                    <span class="p-float-label">
                                        <Calendar id="monthpicker" v-model="fechaF" view="month" dateFormat="MM/yy"
                                            :yearNavigator="true" yearRange="2020:2030" />
                                        <label>Fecha Final</label>
                                    </span>
                                    <Button icon="pi pi-search" class="p-button-success pl-4 pr-4" @click="getCuentas()" />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="field col-12 mt-2 mb-0">
                        <hr size=7 color="#E64747">
                    </div>
                </div>
                <DataTable filterDisplay="menu" :exportFilename="nombreExcel" ref="conceptos" showGridlines
                    :filters.sync="filtros" class="mt-1 p-datatable-sm" :value="itemsCuentas" :scrollable="true"
                    scrollHeight="550px" scrollDirection="both" :loading="loadingCuentas" removableSort
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true"
                    :rows="15" responsiveLayout="scroll">
                    <template #header>
                        <div class="p-fluid grid">
                            <div class="col-4 text-left ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                                </span>
                            </div>
                            <div class="col-8 text-right ">
                                <Button type="button" icon="pi pi-filter-slash" style="width: 100px;" label="Limpiar"
                                    class="p-button-outlined mr-2" @click="limpiarFiltros()" />
                                <Button icon="pi pi-external-link" style="width: 200px;" label="Exportar Excel"
                                    @click="exportar($event)" />
                            </div>
                        </div>
                        <div class="p-fluid grid">
                            <div class="col-3 text-center">
                                <span>Total Pagado:</span>
                                <h3>{{ sumaPagado }}</h3>
                            </div>
                            <div class="col-3 text-center">
                                <span>Total Facturado:</span>
                                <h3>{{ sumaFacturado }}</h3>
                            </div>
                            <div class="col-3 text-center">
                                <span>Total Notas Credito:</span>
                                <h3>{{ sumaNotasCredito }}</h3>
                            </div>
                            <div class="col-3 text-center">
                                <span>Total Pendiente:</span>
                                <h3>{{ sumaPendiente }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                        :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                        <template #body="slotProps">
                            <Button @click="verPDF(slotProps.data)" icon="pi pi-file-pdf"
                                class="p-button-rounded p-button-danger" v-tooltip.bottom="'Ver PDF'" />
                        </template>
                    </Column>
                    <Column sortable field="serie" header="Serie"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="folio" header="Folio"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="rfc" header="RFC"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>

                    <Column sortable field="nombre" header="Nombre"
                        :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                        :styles="{ 'width': '350px', 'font-size': '90%', }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                    <Column sortable field="moneda" header="Moneda"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'width': '250px', 'font-size': '90%', }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>

                    <Column sortable field="totalPagado" header="Total Pagado"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.totalPagado) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="totalFacturado" header="Total Facturado"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.totalFacturado) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>

                    <Column sortable field="totalNc" header="Total Notas de Credito"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.totalNc) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>

                    <Column sortable field="totalPendiente" header="Total Pendiente"
                        :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                        :styles="{ 'width': '200px', 'font-size': '90%', }">
                        <template #body="{ data }">
                            <span>{{ formatCurrency(data.totalPendiente) }}</span>
                        </template>
                        <template #filter="{ filterModel }">
                            <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                        </template>
                    </Column>
                    <Column sortable field="folioFiscal" header="Folio Fiscal"
                        :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                        :styles="{ 'width': '400px', 'font-size': '90%', }">
                        <template #filter="{ filterModel }">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="Buscar" />
                        </template>
                    </Column>
                </DataTable>
            </template>
        </Card>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import VistaPdf from '../Pdf/ComprobantePdf.vue'

export default {
    components: {
        VistaPdf,
    },
    data() {
        return {
            fechaI: new Date(),
            fechaF: new Date(),
            itemsCuentas: [],
            nombreExcel: '',
            loadingCuentas: false,
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'totalPagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalFacturado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalNc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'totalPendiente': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            },
            dialogPdf: false,
            loadingSpinner: false
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        sumaPagado() {
            let total = 0;
            for (let item of this.itemsCuentas) {
                total += item.totalPagado;
            }

            return this.formatCurrency(total);
        },
        sumaFacturado() {
            let total = 0;
            for (let item of this.itemsCuentas) {
                total += item.totalFacturado;
            }

            return this.formatCurrency(total);
        },
        sumaNotasCredito() {
            let total = 0;
            for (let item of this.itemsCuentas) {
                total += item.totalNc;
            }

            return this.formatCurrency(total);
        },
        sumaPendiente() {
            let total = 0;
            for (let item of this.itemsCuentas) {
                total += item.totalPendiente;
            }

            return this.formatCurrency(total);
        },
    },
    created() {
    },
    methods: {
        async getCuentas() {
            this.loadingCuentas = true;
            let fI = moment(this.fechaI).format('YYYY-MM-01')
            let fF = moment(this.fechaF).format('YYYY-MM-01')
            try {
                let result = await axios.get('ComplementoDePago/GetCxC/' + this.token.empresaBase + '/' + fI + '/' + fF);
                this.itemsCuentas = result.data
                console.log(result.data)

                this.loadingCuentas = false;
            } catch (error) {
                console.log(error)
                this.loadingCuentas = false;
            }
        },
        async verPDF(item) {
            this.loadingSpinner = true

            console.log(item)
            try {
                let response = await axios.get('Comprobantes/GetComprobanteFolioFiscalI/' + this.token.empresaBase + '/' + item.folioFiscal);
                let respuesta = response.data;
                this.$store.state.comprobanteStore = { ...respuesta[0] }
                console.log(this.$store.state.comprobanteStore)
                this.loadingSpinner = false
                this.dialogPdf = true;
            } catch (error) {
                this.loadingSpinner = false
                console.log(error)
            }
        },
        exportar() {
            let fI = moment(this.fechaI).format('MMMM/YYYY')
            let fF = moment(this.fechaF).format('MMMM/YYYY')

            this.nombreExcel = 'CuentasPorPagar_' + fI + '_' + fF
            this.$refs.conceptos.exportCSV();
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        formatDate(value) {
            moment.locale('es-mx');
            return moment(value).format('DD-MMMM-YYYY')
        },
        closeDialogPdf() {
            this.dialogPdf = false;
        },
        limpiarFiltros() {
            this.filtros = {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'monedaDR.moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'impPagado': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'impSaldoAnt': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'impSaldoInsoluto': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            }
        },
    },
}
</script>