import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null,
    usuario: null,

    empresaStore: {
      idEmpresa: 0,
      rfc: '',
      nombreEmpresa: '',
      empresa: '',
      codigoPostal: '',
      regimenFiscal: { regimenFiscal: '' },
      usoCfdi: { usoCfdi: '' },
      correos: []
    },

    correoStore: {
      nombreCorreo: '',
      correo: '',
      password: '',
      puerto: '',
      host: '',
    },

    sellosStore: {
      rfc: '',
      csdContra: '',
      csdKey: {
        base64: '',
      },
      csdCer: {
        base64: '',
      },
    },
    listaDetallesIngresosStore: [],
    listaIngresosRFCStore: [],

    listaConceptosGeneralesStore: [],
    listaFuentesFinanciamiento: [],
    listaFondosStore: [],
  },

  getters: {

  },

  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUsuario(state, usuario) {
      state.usuario = usuario
    }
  },
  actions: {
    guardarToken({ commit }, token) {
      commit("setToken", token)
      commit("setUsuario", decode(token))
      localStorage.setItem("token", token)
    },
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      if (token) {
        commit("setToken", token)
        commit("setUsuario", decode(token))
        router.push({ name: 'Home' })
      }
    },
    salir({ commit }) {
      commit("setToken", null)
      commit("setUsuario", null)
      localStorage.removeItem("token")
      router.push({ name: 'Login' })
    }
  },
  modules: {
  }
})
