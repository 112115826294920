<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Cuentas por Cobrar por Periodo">
                    <CuentasCobrarPeriodo></CuentasCobrarPeriodo>
                </TabPanel>
                <TabPanel header="Cuentas por Cobrar por RFC">
                    <CuentasCobrarRfc></CuentasCobrarRfc>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import CuentasCobrarPeriodo from './CuentasCobrarPeriodo.vue'
import CuentasCobrarRfc from './CuentasCobrarRfc.vue'
export default {
    components: { CuentasCobrarPeriodo, CuentasCobrarRfc },
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>