<template>
    <div class="padre">
        <div v-if="loadingInicio == 1" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
            <ProgressSpinner style="vertical-align:middle;" />
        </div>
        <div class="p-d-flex">
            <Toast />
        </div>
        <div class="hijo">
            <div class="mt-8 login-container" v-if="loadingInicio == 0">
                <div class="login">
                    <div class="login-items" style="position: relative; left: ">
                        <img class="text-center justify-center" src="../assets/logo_contago.jpg" alt="Image" width="350" />
                        <div class="text-center mt-2 p-fluid grid">
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <InputText v-model="nombreU" style="text-transform: uppercase" />
                                    <label>Usuario</label>
                                </span>
                            </div>
                            <div style="position: relative; left: " class="text-center justify-center">
                                <div class="text-center justify-center field col-12"
                                    style="justify-content:space-between; text-align:center; align-items: center;">
                                    <v-otp-input class="justify-center ml-7" ref="otpInput" input-classes="otp-input"
                                        separator="-" :num-inputs="4" :should-auto-focus="true" v-model="password"
                                        :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete"
                                        type="password" input-type="password" />
                                </div>
                            </div>
                            <div class="field col-12">
                                <span class="p-float-label">
                                    <div v-if="loadingEmpresa == 1">
                                        <ProgressBar mode="indeterminate" style="height: .5em" />
                                    </div>
                                    <Dropdown autoWidth="false" class="dropdown" dataKey="nombreBase"
                                        placeholder="Seleccione su empresa" v-model="selectEmpresa" :options="itemsEmpresa"
                                        optionLabel="nombre_e" autofocus />
                                </span>
                                <Button @click="ingresar()" label="INGRESAR" class="p-button-primary" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            nombreU: "",
            password: "",

            selectEmpresa: {},
            itemsEmpresa: [],

            idUsuario: null,
            nombreUsuario: null,
            rolUsuario: null,
            empresa: null,
            empresaBase: null,
            rfc: "",
            firma: null,

            loadingEmpresa: 0,
            loadingInicio: 0,
        };
    },
    methods: {
        async validarUsuario() {
            this.loadingEmpresa = 1
            if (this.nombreU == "") {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Ingrese el usuario",
                    life: 3000,
                });
                return;
            }
            if (this.password == "") {
                this.$toast.add({
                    severity: "error",
                    summary: "Error Message",
                    detail: "Ingrese la contraseña",
                    life: 3000,
                });

                return;
            }
            //VALIDAMOS QUE EL USUARIO SEA VALIDO
            try {
                let respuesta = await axios.post(
                    "https://api-framework.contago.com.mx/api/Usuarios/ValidaAsync",
                    {
                        nombre: this.nombreU,
                        password: this.password,
                    }
                );
                this.idUsuario = respuesta.data.idUsuario_;
                this.rolUsuario = respuesta.data.rol_;
                this.firma = respuesta.data.firma_;
                this.rfc = respuesta.data.rfc_;
                this.correo = respuesta.data.correo_;
                this.listaEmpresas(respuesta.data.idUsuario_);
                this.nombreUsuario = respuesta.data.nombre_;
                this.loadingEmpresa = 0
                console.log(respuesta.data);
            } catch (err) {
                console.log(err.response.status);
                this.loadingEmpresa = 0

                if (err.response.status == 400) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Usuario inexistente",
                        life: 3000,
                    });
                } else if (err.response.status == 404) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Contraseña incorrecta",
                        life: 3000,
                    });
                } else if (err.response.status == 401) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: "El usuario ya cuenta con una sesión iniciada",
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Datos incorrectos, verifique su usuario y contraseña",
                        life: 3000,
                    });
                }
            }
        },
        ingresar() {
            this.loadingInicio = 1
            this.empresa = this.selectEmpresa.nombre_e;
            this.empresaBase = this.selectEmpresa.nombreBase;
            this.rfc = this.selectEmpresa.rfc;

            if (!this.selectEmpresa.nombre_e) {
                this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: "Seleccione una empresa de la lista",
                    life: 3000,
                });
                return;
            }
            console.log(this.empresaBase);
            console.log(this.empresa);
            axios
                .post("https://api-framework.contago.com.mx/api/Usuarios/Login", {
                    idUsuario: this.idUsuario,
                    nombre: this.nombreUsuario,
                    rol: this.rolUsuario,
                    empresa: this.empresa,
                    empresaBase: this.empresaBase,
                    firma: this.firma,
                    rfc: this.rfc,
                })
                .then((response) => {
                    console.log(response.data);
                    this.loadingInicio = 0
                    return response.data;
                })
                .then((data) => {
                    this.$store.dispatch("guardarToken", data.token);
                    this.$router.push({ name: "Home" });
                    this.nombre = "";
                    this.password = "";
                })
                .catch((err) => {
                    if (err.response.status == 500) {
                        this.$toast.add({
                            severity: "error",
                            summary: "Error",
                            detail: "Error",
                            life: 3000,
                        });
                        console.log(err.response.status);
                        this.loadingInicio = 0
                    }
                });
        },

        listaEmpresas(item) {
            axios
                .get(
                    "https://api-framework.contago.com.mx/api/Usuarios/Empresas/" +
                    item +
                    "/" +
                    "ERP"
                )
                .then((response) => {
                    this.itemsEmpresa = response.data;
                    console.log(this.itemsEmpresa);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        handleOnComplete(value) {
            console.log("OTP completado: ", value);
            this.password = value;
            this.validarUsuario();
        },
        handleOnChange(value) {
            console.log("OTP cambio: ", value);
            this.itemsEmpresa = [];
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
    },
};
</script>
<style>
.p-dialog .p-dialog-content {
    overflow-y: visible !important;
}

.p-dialog-mask {
    position: absolute;
    pointer-events: auto;
    /* (optional, doesn't cover whole area when scroll presents) */
}

.dropdown .p-dropdown-items-wrapper {
    max-height: 420px;
    max-width: 420px;
}

.padre {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hijo {
    width: auto;
}

.otp-input {
    width: 45px;
    height: 45px;
    padding: 5px;
    margin: 12px;
    margin-right: 10px;
    font-size: 20px;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
    margin: 0;
}

/* LOGIN */
.container {
    width: 100%;
    height: 150vh;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    display: flex;
    width: 450px;
    height: 460px;
    margin-top: 100px;
    background: #0000;
    /* box-shadow: 5px 4px 4px 4px rgba(0, 0, 0, 0.2); */
}

.register,
.login {
    flex: 1;
}

.register {
    position: relative;
    width: 20px;
}

.register h2 {
    color: #797979;
    margin-bottom: 30px;
}

.register input {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    margin-bottom: 25px;
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom: 1px solid #a8a8a8;
    color: #333;
}

.register input:active,
.register input:focus {
    outline: none;
}

.register .submit {
    width: auto;
    padding: 10px;
    background: #f17108;
    color: #0000;
    font-size: 12px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.login {
    padding: 20px;
    width: 300px;
}

.login h2 {
    color: #fff;
    margin-bottom: 45px;
}

.login button i {
    position: absolute;
    top: 12px;
    left: 12px;
}

.login button {
    width: 100%;
    margin: 15px 0px;
    padding: 10px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
}

.login .fb {
    background: #3b5999;
    border-style: none;
    color: #fff;
}

.login .tw {
    background: #55acef;
    border-style: none;
    color: #fff;
}

.login .correo {
    background: #33d4ce;
    border-style: none;
    color: #fff;
}

@media only screen and (max-width: 540px) {
    .login-container {
        display: block;
    }

    .register,
    .login {
        flex: none;
    }

    .register::after {
        top: unset;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
    }
}
</style> 