<template>
  <div id="app">
    <!-- DIALOGO DE LOADING -->
    <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
      <ProgressBar mode="indeterminate" style="height: 0.5em" />
      <span>{{ loadingMensaje }}</span>
    </Dialog>

    <div>
      <Toolbar id="border" style="background-color: #E64747;" class="pl-0 pt-2 pr-0 border" v-if="logueado">
        <template #start>
          <i @click="visibleLeft = true" style="font-size: 25px; cursor: pointer" class="ml-4 mr-4 pi pi-bars"></i>
          <!-- <h4>Ingresos</h4> -->
          <h4>{{ $store.state.usuario.empresa }}</h4>
        </template>
        <template #end>
          <h4>{{ $store.state.usuario.rfc }}</h4>
          <div type="button" @click="toggle">
            <Avatar style="cursor: pointer" type="button" icon="mdi mdi-cog-outline" class="ml-5 mr-5" size="large"
              shape="circle" aria-haspopup="true" aria-controls="overlay_tmenu" />
          </div>
          <TieredMenu id="overlay_tmenu" ref="menu" :model="itemsSalir" :popup="true" />
        </template>
      </Toolbar>
      <Sidebar :visible.sync="visibleLeft" :showCloseIcon="false" :baseZIndex="1000">
        <div class="sidebar open">
          <div @click="inicio()" style="cursor: pointer">
            <img src="../src/assets/logo_contago.jpg" height="70" />
          </div>
          <ProgressBar :value="100" style="height: 0.3em" />
          <ul class="nav-list">
            <li>
              <a @click="general()">
                <i class='mdi mdi-file-document-plus'></i>
                <span class="links_name">Ingresos</span>
              </a>
            </li>
            <li>
              <a @click="Iva()">
                <i class='mdi mdi-file-document-plus'></i>
                <span class="links_name">IVA</span>
              </a>
            </li>
            <li>
              <a @click="Conceptos()">
                <i class='mdi mdi-file-document-plus'></i>
                <span class="links_name">Conceptos</span>
              </a>
            </li>
            <!-- <li>
              <a @click="CuentasCobrar()">
                <i class='mdi mdi-file-document-plus'></i>
                <span class="links_name">Cuentas por Cobrar</span>
              </a>
            </li> -->
            <li>
              <a @click="IngresosOP()">
                <i class='mdi mdi-file-document-plus'></i>
                <span class="links_name">Ingresos OP</span>
              </a>
            </li>
          </ul>
        </div>

        <!-- <PanelMenu :model="items" /> -->
      </Sidebar>
    </div>

    <router-view />
    <div id="footer" class="footer"></div>
    <span hidden style="color: black;">{{ $store.state.usuario }}</span>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      loadingMensaje: "",
      nombreUsuario: "",
      visibleLeft: false,
      itemsSalir: [
        {
          label: "Configuracion",
          icon: "pi pi-fw pi-cog",
          command: (event) => {
            this.configuracion();
          },
        },
        {
          separator: true,
        },
        {
          label: "Salir",
          icon: "pi pi-fw pi-power-off",
          command: (event) => {
            this.salir();
          },
        },
      ],


    };
  },
  mounted() {

    this.obtieneToken();

  },
  computed: {

    logueado() {
      return this.$store.state.usuario;
    },
    obtieneEmpresa() {
      return this.$store.state.usuario.empresa;
    },
    obtieneUsuario() {
      return this.$store.state.usuario.nombre;
    },
    Usuario() {
      return this.$store.state.usuario.rol;
    },

  },
  created() {
    // this.a();
    console.log(this.$store.state.usuario)

    this.$store.dispatch("autoLogin");
  },
  methods: {
    general() {
      this.$router.push({ name: 'General' }),
        this.visibleLeft = false
    },
    obtieneToken() {
      const cookie = document.cookie;
      const cookieArray = cookie.split('; ');
      let token = null;

      for (let i = 0; i < cookieArray.length; i++) {
        const cookieItem = cookieArray[i].split('=');
        if (cookieItem[0] === 'seguroContago') {
          token = cookieItem[1];
          break;
        }
      }
      this.$store.dispatch("guardarToken", token);
      this.$store.dispatch("autoLogin");

      console.log('2', token);
    },
    Conceptos() {
      this.$router.push({ name: 'Conceptos' }),
        this.visibleLeft = false
    },
    CuentasCobrar() {
      this.$router.push({ name: 'CuentasCobrar' }),
        this.visibleLeft = false
    },
    IngresosOP() {
      this.$router.push({ name: 'IngresosOP' }),
        this.visibleLeft = false
    },

    Iva() {
      this.$router.push({ name: 'Iva' }),
        this.visibleLeft = false
    },

    inicio() {
      this.$router.push({ name: "Home" }), (this.visibleLeft = false);
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    configuracion() {
      this.$router.push({ name: "Configuracion" });
      let empresa = {
        logo: {
          extencion: '',
          base64: '',
        },
        idEmpresa: 0,
        rfc: '',
        domicilioFiscal: '',
        nombre: '',
        regimenFiscal: {}
      }
      this.$store.state.empresaStore = { ...empresa }

    },
    async salir() {
      this.loading = true;
      this.loadingMensaje = "Cerrando Sesión...";
      let respuesta;
      try {
        respuesta = await axios.put(
          "https://api-framework.contago.com.mx/api/Usuarios/actualizaSesion/" +
          this.$store.state.usuario.nombre +
          "/INACTIVA"
        );
        this.$store.dispatch("salir");

        //INICIALIZAMOS LOS CATALOGOS
        let correo = {
          asunto: '',
          mensaje: '',
          emisor: {},
          archivos: [],
          receptores: [],
        }
        let empresa = {
          logo: {
            extencion: '',
            base64: '',
          },
          idEmpresa: 0,
          rfc: '',
          domicilioFiscal: '',
          nombre: '',
          regimenFiscal: {}
        }

        this.$store.state.empresaStore = { ...empresa }
        this.$store.state.correoStore = { ...correo }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>
<style>
.p-sidebar .p-sidebar-header {
  padding: 0rem;
}

.p-sidebar .p-sidebar-content {
  padding: 0rem;
}

.p-panelmenu .p-panelmenu-header>a {
  padding: 1.5rem;
  border: 0 none;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  font-weight: 400;
  border-radius: 4px;
  transition: none;
}

.p-panelmenu .p-panelmenu-header>a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header>a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled)>a:hover {
  background: #BBDEFB;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight>a {
  background: #BBDEFB;
  border-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
  border-color: transparent;
  background: #BBDEFB;
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 0 none;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem 1rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
  transition: none;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #E3F2FD;

}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.87);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.6);
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header>a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header>a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover>a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover>a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header>a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight)>a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.border {
  /* border-top: 3px; */
  background-color: #E64747;
  height: 60px;
}

.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 20px;
  width: 100%;
  background: #E64747;
}

.p-toolbar {
  padding: 0.5rem;
  border-radius: 0px;
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin: 0px;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}


.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  /* border: 1px solid #e5e5e5; */
  border-radius: 4px;
  /* width: 12.5rem; */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.p-tieredmenu .p-menuitem-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  overflow: hidden;
  position: relative;
  padding: 0.75rem 1.25rem;
  color: #000000;
  border-radius: 0;
  transition: box-shadow 0.2s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-text {
  line-height: 1;
  padding-left: 10px;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: 25px;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  max-height: 100%;
  width: 78px;
  background: white;
  padding: 6px 14px;
  z-index: 99;
  transition: all 0.5s ease;
  overflow-y: scroll;
}

.sidebar.open {
  width: 320px;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: rgb(145, 1, 1);
  font-size: 20px;
  font-weight: 600;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
  text-align: right;
}

.sidebar i {
  color: #11101D;
  height: 60px;
  min-width: 50px;
  font-size: 38px;
  text-align: center;
  line-height: 60px;
}

.sidebar .nav-list {
  margin-top: 20px;
  height: 100%;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li .tooltip {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar li:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.sidebar.open li .tooltip {
  display: none;
}

.sidebar input {
  font-size: 15px;
  color: #1d1b31;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  border-radius: 12px;
  transition: all 0.5s ease;
  background: #fff;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  background: #fff;
  color: #1d1b31;
}

.sidebar.open .bx-search:hover {
  background: #1d1b31;
  color: #FFF;
}

.sidebar .bx-search:hover {
  background: #FFF;
  color: hsl(0deg 66% 47%);
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: #fff;
  cursor: pointer;
}

.sidebar li a:hover {
  background: #FFCDD2;
}

.sidebar li a .links_name {
  color: #11101D;
  font-size: 15px;
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
  margin-left: 20px;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;

}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #C62828;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 23px;
  border-radius: 12px;
  color: rgba(0, 0, 0, 0.5);
  ;
}

.sidebar li.profile {
  position: fixed;
  height: 60px;
  width: 78px;
  left: 0;
  bottom: -8px;
  padding: 10px 14px;
  background: #1d1b31;
  transition: all 0.5s ease;
  overflow: hidden;
}

.sidebar.open li.profile {
  width: 250px;
}

.sidebar li .profile-details {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.sidebar li img {
  height: 45px;
  width: 45px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background: #1d1b31;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}

.p-datatable .p-datatable-thead>tr>th,
.p-datatable.p-datatable-sm .p-datatable-thead>tr>th {
  border-bottom: 1px solid #C62828;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}
</style>
