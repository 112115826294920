<template>
    <div>
        <Dialog :visible.sync="dialogPdf" :style="{ width: '850px' }" :closable="false" :modal="true" position="top">
            <vista-pdf @cierraDialogPdf="closeDialogPdf"></vista-pdf>
        </Dialog>

        <!-- DIALOGO DE SPINNER -->
        <Dialog class="dialogoTransparente shadow-none" :showHeader="false" style="background: transparent;"
            :visible.sync="loadingSpinner" :closable="false" position="center" :containerStyle="{ width: '300px' }">
            <ProgressSpinner />
        </Dialog>

        <DataTable filterDisplay="menu" removableSort ref="detalles" :exportFilename="nombreExcel" showGridlines
            :filters.sync="filtros" scrollHeight="550px" class="mt-1 p-datatable-sm" :value="listaDetalles"
            :scrollable="true" :rows="15"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 15, 25, 50]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true">
            <template #header>
                <div class="flex justify-content-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                    </span>
                    <Button icon="pi pi-external-link" label="Exportar Excel" @click="exportarDetalles($event)" />
                </div>
            </template>
            <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                <template #body="slotProps">
                    <Button @click="verPDF(slotProps.data)" icon="pi pi-file-pdf" class="p-button-rounded p-button-danger"
                        v-tooltip.bottom="'Ver PDF'" />
                </template>
            </Column>
            <Column sortable field="folioFiscal" header="Folio Fiscal"
                :styles="{ 'min-width': '340px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="serie" header="Serie" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '100px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="folio" header="Folio" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '200px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="fecha" header="Fecha" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '150px', 'font-size': '90%', }">
                <template #body="{ data }">
                    <span>{{ formatDate(data.fecha) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="rfc" header="RFC" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '150px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="nombre" header="Nombre" :bodyStyle="{ 'justify-content': 'left', overflow: 'visible' }"
                :styles="{ 'min-width': '500px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="subTotal" header="SubTotal"
                :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                :styles="{ 'min-width': '200px', 'font-size': '90%', }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.subTotal) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>
            <Column sortable field="descuento" header="Descuento"
                :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                :styles="{ 'min-width': '200px', 'font-size': '90%', }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.descuento) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>
            <Column sortable field="total" header="Total" :bodyStyle="{ 'justify-content': 'right', overflow: 'visible' }"
                :styles="{ 'min-width': '200px', 'font-size': '90%', }">
                <template #body="{ data }">
                    <span>{{ formatCurrency(data.total) }}</span>
                </template>
                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" mode="currency" currency="USD" locale="en-US" />
                </template>
            </Column>
            <Column sortable field="moneda" header="Moneda"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '200px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="formaPago" header="Forma de Pago"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '300px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="metodoPago" header="Metodo de Pago"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '300px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="tipoComprobante" header="Tipo de Comprobante"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '180px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>
            <Column sortable field="estatus" header="Estatus"
                :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }"
                :styles="{ 'min-width': '200px', 'font-size': '90%', }">
                <template #filter="{ filterModel }">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar" />
                </template>
            </Column>

        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import VistaPdf from '../Pdf/ComprobantePdf'

export default {
    components: {
        VistaPdf,
    },
    data() {
        return {
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                'folioFiscal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'serie': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'folio': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'fecha': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'rfc': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'nombre': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'subTotal': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'descuento': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'total': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'moneda': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'formaPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'metodoPago': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'tipoComprobante': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'estatus': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },

            },
            nombreExcel: '',
            tituloDetalles: 'Comprobante',
            dialogPdf: false,
            loadingSpinner: false
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        listaDetalles() {
            return this.$store.state.listaDetallesIngresosStore
        }


    },
    created() {
    },
    methods: {
        async verPDF(item) {
            this.loadingSpinner = true
            console.log(item)
            try {
                let response = await axios.get('Comprobantes/GetComprobanteFolioFiscal/' + this.token.empresaBase + '/' + item.folioFiscal);
                let respuesta = response.data;
                this.$store.state.comprobanteStore = { ...respuesta[0] }
                console.log(this.$store.state.comprobanteStore)
                this.loadingSpinner = false

                this.dialogPdf = true;
            } catch (error) {
                console.log(error)
                this.loadingSpinner = false

            }
        },

        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        },
        formatDate(value) {
            moment.locale('es-mx');
            return moment(value).format('DD-MMMM-YYYY')
        },
        exportarDetalles() {
            this.nombreExcel = 'Detalles'
            this.$refs.detalles.exportCSV();
        },

        closeDialogPdf() {
            this.dialogPdf = false;
        },
    },
}
</script>
<style>
.dialogoTransparente .p-dialog .p-dialog-content {
    background: transparent;
}
</style>