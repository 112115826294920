import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index'

import Login from '../views/Login.vue'
import Configuracion from '../views/Configuracion.vue'
import General from '../components/General/General.vue'
import IngresosComparativa from '../components/General/IngresosComparativa.vue'
import IngresosMes from '../components/General/IngresosMes.vue'
import IngresosPeriodo from '../components/General/IngresosPeriodo.vue'
import IngresosRFC from '../components/General/IngresosRFC.vue'
import Conceptos from '../components/Conceptos/Conceptos.vue'
import CuentasCobrar from '../components/CuentasCobrar/CuentasCobrar.vue'
import IngresosOP from '../components/IngresosOP/IngresosOP.vue'
import Iva from '../components/IVA/Iva.vue'


Vue.use(VueRouter)

const routes = [
  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  { path: '/', name: 'Home', component: HomeView, meta: { Administrador: true } },
  { path: '/Configuracion', name: 'Configuracion', component: Configuracion, meta: { Administrador: true } },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  { path: '/General', name: 'General', component: General, meta: { Administrador: true } },
  { path: '/IngresosComparativa', name: 'IngresosComparativa', component: IngresosComparativa, meta: { Administrador: true } },
  { path: '/IngresosMes', name: 'IngresosMes', component: IngresosMes, meta: { Administrador: true } },
  { path: '/IngresosPeriodo', name: 'IngresosPeriodo', component: IngresosPeriodo, meta: { Administrador: true } },
  { path: '/IngresosRFC', name: 'IngresosRFC', component: IngresosRFC, meta: { Administrador: true } },
  { path: '/Conceptos', name: 'Conceptos', component: Conceptos, meta: { Administrador: true } },
  { path: '/CuentasCobrar', name: 'CuentasCobrar', component: CuentasCobrar, meta: { Administrador: true } },
  { path: '/IngresosOP', name: 'IngresosOP', component: IngresosOP, meta: { Administrador: true } },
  { path: '/Iva', name: 'Iva', component: Iva, meta: { Administrador: true } },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.rol == 'Administrador') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  } else {
    next({
      name: 'Login'
    })
  }
})

export default router
