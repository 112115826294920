<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Fuente de Financiamiento">
                    <ListaFuenteFinanciamiento></ListaFuenteFinanciamiento>
                </TabPanel>
                <TabPanel header="Fondos">
                    <ListaFondos></ListaFondos>
                </TabPanel>
                <TabPanel header="Reporte por fondo">
                    <ReporteFondo></ReporteFondo>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import ListaFuenteFinanciamiento from './ListaFuenteFinanciamiento.vue';
import ListaFondos from './ListaFondos.vue';
import ReporteFondo from './ReporteFondo.vue';

export default {
    components: {
        ListaFuenteFinanciamiento,
        ListaFondos,
        ReporteFondo,
    },
    data() {
        return {

        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {

    },
    methods: {

    },
}
</script>