<template>
    <div class="card">
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <div class="flex justify-content-between flex-wrap pb-2">
            <Button icon="pi pi-times" class="p-button-rounded p-button-danger p-button-outlined" @click="CloseVentana()" />
            <h2>Fondo</h2>
            <Button @click="PostOrPut()" icon="pi pi-save" class="p-button-rounded p-button-success p-button-outlined" />
        </div>
        <div class="p-fluid grid">
            <!-- CLAVE -->
            <div class="col-12 text-left">
                <span class="p-float-label">
                    <InputText type="text" v-model="fondo.clave" />
                    <label>Clave</label>
                </span>
            </div>
            <!-- DESCRIPCION -->
            <div class="col-12 text-left">
                <span class="p-float-label">
                    <InputText type="text" v-model="fondo.descripcion" />
                    <label>Descripción</label>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'

export default {
    components: {

    },
    data() {
        return {
            loadingMensaje: '',
            loading: false,
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        fondo() {
            return this.$store.state.fondoStore
        },
    },
    created() {
    },
    methods: {
        CloseVentana() {
            this.$emit("cierraDialogFondo");
        },
        async PostOrPut() {
            await this.Post();
        },
        async Post() {
            this.loading = true;
            this.loadingMensaje = 'Guardando, espere...'
            try {
                let ObjFondo = {
                    clave: this.fondo.clave,
                    descripcion: this.fondo.descripcion,
                }
                let response = await axios.post('ReporteComprobantesIngresos/PostFondo/' + this.token.empresaBase, ObjFondo)
                console.log(response.data)
                this.loading = false;
                this.$store.state.listaFondosStore.push(ObjFondo)
                this.CloseVentana();
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },
    },
}
</script>