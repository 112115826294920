<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Ingresos por Mes">
                    <IngresosMes></IngresosMes>
                </TabPanel>
                <TabPanel header="Ingresos por Periodo">
                    <IngresosPeriodo></IngresosPeriodo>
                </TabPanel>
                <TabPanel header="Comparativa">
                    <IngresosComparativa></IngresosComparativa>
                </TabPanel>
                <TabPanel header="Ingresos por RFC">
                    <IngresosRFC></IngresosRFC>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import IngresosMes from './IngresosMes.vue'
import IngresosComparativa from './IngresosComparativa.vue'
import IngresosPeriodo from './IngresosPeriodo.vue'
import IngresosRFC from './IngresosRFC.vue'
export default {
    components: { IngresosMes, IngresosComparativa, IngresosPeriodo, IngresosRFC },
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>