<template>
    <div class="card">
        <!-- DIALOGO DE LOADING -->
        <Dialog :visible.sync="loading" :closable="false" :containerStyle="{ width: '300px' }" :modal="true">
            <ProgressBar mode="indeterminate" style="height: 0.5em" />
            <span>{{ loadingMensaje }}</span>
        </Dialog>

        <Dialog :visible.sync="dialogFondo" :style="{ width: '5000px' }" :closable="false" :modal="true" position="top">
            <Fondo @cierraDialogFondo="dialogFondo = false"></Fondo>
        </Dialog>

        <Dialog :visible.sync="dialogFondoSinConciliar" :style="{ width: '5000px' }" :closable="false" :modal="true"
            position="top">
            <DataTable showGridlines scrollHeight="550px" class="mt-1 p-datatable-sm" :value="itemsFondoSinConciliar"
                :scrollable="true" :rows="15"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[5, 10, 15, 25, 50]"
                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true">
                <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                    :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                    <template #body="slotProps">
                        <Button @click="OpenDialogFondo(slotProps.data)" icon="pi pi-check"
                            class="p-button-rounded p-button-success" v-tooltip.bottom="'Agregar fondo'" />
                    </template>
                </Column>
                <Column sortable field="clave" header="Clave" :styles="{ 'min-width': '150px' }">
                </Column>
            </DataTable>
        </Dialog>

        <DataTable removableSort ref="detalles" :exportFilename="nombreExcel" showGridlines :filters.sync="filtros"
            scrollHeight="550px" class="mt-1 p-datatable-sm" :value="items" :scrollable="true" :rows="15"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 15, 25, 50]"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} Registros." :paginator="true">
            <template #header>
                <div class="flex justify-content-between">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filtros['global'].value" placeholder="Buscar" />
                    </span>
                    <div>
                        <Button icon="pi pi-external-link" label="Nuevo" @click="dialogFondo = true" class="mr-1" />
                        <Button icon="pi pi-external-link" label="Conciliar" @click="OpenConciliaFondos()" />
                    </div>
                </div>
            </template>
            <!-- <Column header="Acciones" :bodyStyle="{ 'justify-content': 'center', overflow: 'visible' }" frozen
                :styles="{ 'flex-grow': '1', 'flex-basis': '50px', 'min-width': '5rem', }">
                <template #body="slotProps">
                    <Button @click="verPDF(slotProps.data)" icon="pi pi-file-pdf"
                            class="p-button-rounded p-button-danger" v-tooltip.bottom="'Ver PDF'" />
                </template>
            </Column> -->
            <Column sortable field="clave" header="Clave" :styles="{ 'min-width': '150px' }">
            </Column>
            <Column sortable field="descripcion" header="Descripción" :styles="{ 'min-width': '400px' }">
            </Column>
        </DataTable>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from "primevue/api/";
import Fondo from './Fondo.vue';

export default {
    components: {
        Fondo,
    },
    data() {
        return {
            filtros: {
                global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            nombreExcel: '',
            dialogFondo: false,
            loading: false,
            loadingMensaje: '',
            dialogFondoSinConciliar: false,
            itemsFondoSinConciliar: [],
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
        items() {
            return this.$store.state.listaFondosStore;
        },
    },
    created() {
        this.GetFondos();
    },
    methods: {
        async GetFondos() {
            try {
                let response = await axios.get('ReporteComprobantesIngresos/GetFondos/' + this.token.empresaBase)
                console.log(response.data)
                this.$store.state.listaFondosStore = response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async OpenConciliaFondos() {
            this.loading = true;
            this.loadingMensaje = 'Consultando fondos...';
            this.itemsFondoSinConciliar = [];
            try {
                let response = await axios.get('ReporteComprobantesIngresos/GetConciliaFondos/' + this.token.empresaBase)
                console.log(response.data)
                this.loading = false;
                this.dialogFondoSinConciliar = true;
                for (let a of response.data) {
                    let ObjFondo = {
                        clave: a,
                        descripcion: '',
                    }
                    this.itemsFondoSinConciliar.push(ObjFondo);
                    ObjFondo = {}
                }
            } catch (error) {
                console.log(error)
                this.loading = false;
            }
        },

        OpenDialogFondo(item) {
            this.$store.state.fondoStore = { ...item }
            this.dialogFondo = true;
        },
    },
}
</script>