<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Pagos de IVA">

                </TabPanel>
                <TabPanel header="Retenciones de IVA (Emitidos)">

                </TabPanel>
                <TabPanel header="Retenciones de IVA (Recibidos)">

                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    components: {},
    data() {
        return {
        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },
    },
    created() {
    },
    methods: {


    },
}
</script>