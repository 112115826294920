<template>
    <div>
        <div class="card">
            <TabView>
                <TabPanel header="Conceptos Generales">
                    <ConceptosGeneral></ConceptosGeneral>
                </TabPanel>
                <TabPanel header="Conceptos por Descripción">
                    <ConceptosDescripcion></ConceptosDescripcion>
                </TabPanel>
                <TabPanel header="Conceptos por Clave SAT">
                    <ConceptosClaveSAT></ConceptosClaveSAT>
                </TabPanel>
                <TabPanel header="Conceptos por Cliente">
                    <ConceptosCliente></ConceptosCliente>
                </TabPanel>
            </TabView>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import ConceptosGeneral from './ConceptosGeneral.vue';
import ConceptosDescripcion from './ConceptosDescripcion.vue'
import ConceptosClaveSAT from './ConceptosClaveSAT.vue'
import ConceptosCliente from './ConceptosCliente.vue'
export default {
    components: { ConceptosGeneral, ConceptosDescripcion, ConceptosClaveSAT, ConceptosCliente },
    data() {
        return {

        };
    },
    computed: {
        token() {
            return this.$store.state.usuario;
        },


    },
    created() {
    },
    methods: {


    },
}
</script>
<style>
.p-tabview .p-tabview-nav {
    justify-content: center;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #ffe6e6;
    color: #C62828;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: none;
    margin: 0 0 -1px 0;
    font-size: 18px;
    font: bold;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffb4b4;
    border-color: rgba(0, 0, 0, 0.12);
    color: #C62828;
    font-weight: normal;

}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
    z-index: 1;
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #C62828;
    transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-tabview .p-tabview-nav {
    background: #ffe6e6;
    border: solid rgba(0, 0, 0, 0.12);
    border-width: 0 0 1px 0;
}
</style>